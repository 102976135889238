import "core-js/modules/web.dom.iterable";
import { GTM_EVENT } from '@app/utils/defines';
import { globalHistory } from '@reach/router';
import { useEffect, useState } from 'react';
export const categoryNames = categories => {
    const initialCategoryNames = {};
    const CategoryNames = (categories === null || categories === void 0 ? void 0 : categories.reduce((accumulator, currentValue, index) => {
        if (index == 0) {
            return {};
        }
        accumulator["item_category" + (index > 1 ? index : '')] = currentValue.name;
        return accumulator;
    }, initialCategoryNames)) || {};
    return CategoryNames;
};
const discount = (oldPrice, amount) => {
    if (oldPrice) {
        return (oldPrice - amount) / oldPrice;
    }
};
export const selectItemGtmHelper = data => {
    var _data$classifications, _data$classifications2, _data$classifications3, _data$price, _data$supplier;
    let itemVariant = undefined;
    const propertiesArr = (data === null || data === void 0 ? void 0 : data.pn) ? Object.values(data.pn) : data === null || data === void 0 ? void 0 : data.product_properties;
    propertiesArr === null || propertiesArr === void 0 ? void 0 : propertiesArr.forEach((pn, index) => {
        if ((pn === null || pn === void 0 ? void 0 : pn.static_id) === 'S20') {
            itemVariant = pn.value;
        }
    });
    const taxons = (data === null || data === void 0 ? void 0 : (_data$classifications = data.classifications) === null || _data$classifications === void 0 ? void 0 : _data$classifications.length) > 0 ? data === null || data === void 0 ? void 0 : (_data$classifications2 = data.classifications[1].taxon) === null || _data$classifications2 === void 0 ? void 0 : (_data$classifications3 = _data$classifications2.pretty_name) === null || _data$classifications3 === void 0 ? void 0 : _data$classifications3.split(' -> ') : [];
    const item_categories = {};
    taxons === null || taxons === void 0 ? void 0 : taxons.forEach((taxon, index) => {
        if (index != 0) {
            const categoryIndex = index == 1 ? '' : (index - 1).toString();
            item_categories["item_category" + categoryIndex] = taxon;
        }
    });
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        event: GTM_EVENT.SELECT_ITEM,
        ecommerce: {
            items: [{
                    item_name: data === null || data === void 0 ? void 0 : data.name,
                    item_id: data === null || data === void 0 ? void 0 : data.id,
                    price: (data === null || data === void 0 ? void 0 : (_data$price = data.price) === null || _data$price === void 0 ? void 0 : _data$price.amount) ? parseFloat(data.price.amount) : undefined,
                    item_brand: data === null || data === void 0 ? void 0 : (_data$supplier = data.supplier) === null || _data$supplier === void 0 ? void 0 : _data$supplier.name,
                    ...categoryNames(data === null || data === void 0 ? void 0 : data.categories),
                    item_variant: itemVariant,
                    item_list_name: data === null || data === void 0 ? void 0 : data.itemListName,
                    item_list_id: data === null || data === void 0 ? void 0 : data.itemListId,
                    index: data === null || data === void 0 ? void 0 : data.index,
                    quantity: data === null || data === void 0 ? void 0 : data.totalInHand,
                    tag_list: data === null || data === void 0 ? void 0 : data.tags,
                    origin_supplier: data === null || data === void 0 ? void 0 : data.origin_supplier
                }]
        }
    });
};
export const viewPromotionGtmHelper = module => {
    const gtmPromotions = [];
    module.promotional_links.map(element => {
        var _module$metadata_list, _module$metadata_list2, _module$metadata_list3, _module$metadata_list4, _module$metadata_list5, _element$query, _element$image;
        gtmPromotions.push({
            promotion_id: (_module$metadata_list = module.metadata_list) === null || _module$metadata_list === void 0 ? void 0 : _module$metadata_list.module_template,
            promotion_name: "" + ((_module$metadata_list2 = module.metadata_list) === null || _module$metadata_list2 === void 0 ? void 0 : _module$metadata_list2.title_text) + (((_module$metadata_list3 = module.metadata_list) === null || _module$metadata_list3 === void 0 ? void 0 : _module$metadata_list3.title_text) !== '' && ((_module$metadata_list4 = module.metadata_list) === null || _module$metadata_list4 === void 0 ? void 0 : _module$metadata_list4.title_separator) !== '' ? '_' : '') + "\n        " + ((_module$metadata_list5 = module.metadata_list) === null || _module$metadata_list5 === void 0 ? void 0 : _module$metadata_list5.title_separator),
            creative_name: (_element$query = element.query) === null || _element$query === void 0 ? void 0 : _element$query.link_url,
            creative_slot: (_element$image = element.image) === null || _element$image === void 0 ? void 0 : _element$image.url,
            location_id: undefined
        });
    });
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        event: GTM_EVENT.VIEW_PROMOTION,
        ecommerce: {
            items: gtmPromotions
        }
    });
};
export const addToCartGtmHelper = (data, quantity, itemListName, itemListId) => {
    var _data$product_propert, _data$id, _data$product, _data$price2, _data$price3, _data$supplier2, _data$categories, _data$product2;
    let itemVariant = undefined;
    const propertiesArr = data.pn ? Object.values(data.pn) : (_data$product_propert = data === null || data === void 0 ? void 0 : data.product_properties) !== null && _data$product_propert !== void 0 ? _data$product_propert : data === null || data === void 0 ? void 0 : data.productProperties;
    propertiesArr === null || propertiesArr === void 0 ? void 0 : propertiesArr.forEach((pn, index) => {
        if ((pn === null || pn === void 0 ? void 0 : pn.static_id) === 'S20') {
            itemVariant = pn.value;
        }
    });
    const gtmItems = [];
    gtmItems.push({
        item_name: data === null || data === void 0 ? void 0 : data.name,
        item_id: (_data$id = data === null || data === void 0 ? void 0 : data.id) !== null && _data$id !== void 0 ? _data$id : data === null || data === void 0 ? void 0 : (_data$product = data.product) === null || _data$product === void 0 ? void 0 : _data$product.id,
        price: (data === null || data === void 0 ? void 0 : (_data$price2 = data.price) === null || _data$price2 === void 0 ? void 0 : _data$price2.amount) ? parseFloat(data === null || data === void 0 ? void 0 : (_data$price3 = data.price) === null || _data$price3 === void 0 ? void 0 : _data$price3.amount) : (data === null || data === void 0 ? void 0 : data.price) ? parseFloat(data === null || data === void 0 ? void 0 : data.price) : undefined,
        item_brand: data === null || data === void 0 ? void 0 : (_data$supplier2 = data.supplier) === null || _data$supplier2 === void 0 ? void 0 : _data$supplier2.name,
        ...categoryNames((_data$categories = data === null || data === void 0 ? void 0 : data.categories) !== null && _data$categories !== void 0 ? _data$categories : data === null || data === void 0 ? void 0 : (_data$product2 = data.product) === null || _data$product2 === void 0 ? void 0 : _data$product2.categories),
        item_variant: itemVariant,
        item_list_name: itemListName,
        item_list_id: itemListId,
        index: data === null || data === void 0 ? void 0 : data.index,
        quantity: quantity,
        tag_list: data === null || data === void 0 ? void 0 : data.tags,
        origin_supplier: data === null || data === void 0 ? void 0 : data.origin_supplier
    });
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        event: GTM_EVENT.ADD_TO_CART,
        ecommerce: {
            items: gtmItems
        }
    });
};
export const inCartQuantityChangeHelper = (data, quantity) => {
    const changedQty = quantity - data.quantity;
    if (changedQty !== 0) {
        addToCartGtmHelper(data, changedQty, 'cart', 'cart');
    }
};
export const removeFromCartHelper = (item, itemsSelected) => {
    var _item$included, _item$included2;
    const productDiscount = item === null || item === void 0 ? void 0 : (_item$included = item.included) === null || _item$included === void 0 ? void 0 : _item$included.map(item => {
        var _item$attributes$prod, _item$attributes$prod2;
        discount(((_item$attributes$prod = item.attributes.product) === null || _item$attributes$prod === void 0 ? void 0 : _item$attributes$prod.old_prices) && ((_item$attributes$prod2 = item.attributes.product) === null || _item$attributes$prod2 === void 0 ? void 0 : _item$attributes$prod2.old_prices[0]), item.attributes.prices && item.attributes.prices[0].amount);
    });
    const gtmProducts = [];
    const productsInfo = [];
    item === null || item === void 0 ? void 0 : (_item$included2 = item.included) === null || _item$included2 === void 0 ? void 0 : _item$included2.map(item => {
        var _item$attributes, _item$attributes$prod3, _item$attributes2, _item$attributes2$pro, _item$attributes$prod4, _item$attributes$prod5, _item$attributes$prod6, _item$attributes$prod7;
        let itemVariant = undefined;
        (_item$attributes = item.attributes) === null || _item$attributes === void 0 ? void 0 : (_item$attributes$prod3 = _item$attributes.product_properties) === null || _item$attributes$prod3 === void 0 ? void 0 : _item$attributes$prod3.forEach(item => {
            if ((item === null || item === void 0 ? void 0 : item.static_id) === 'S20') {
                itemVariant = item.value;
            }
        });
        const productInfo = {
            id: item.id,
            name: item.attributes.product.name,
            price: {
                amount: item.attributes.prices && item.attributes.prices[0].amount,
                currency: item.attributes.prices && item.attributes.prices[0].currency
            },
            supplier: {
                id: item.attributes.supplier.id,
                name: item.attributes.supplier.name
            },
            tag_list: item === null || item === void 0 ? void 0 : (_item$attributes2 = item.attributes) === null || _item$attributes2 === void 0 ? void 0 : (_item$attributes2$pro = _item$attributes2.product) === null || _item$attributes2$pro === void 0 ? void 0 : _item$attributes2$pro.tag_list,
            sales_unit: item.attributes.prices && item.attributes.prices[0].incremental_orderable_quantity,
            total_on_hand: item.attributes.product.total_on_hand,
            is_discount: ((_item$attributes$prod4 = item.attributes.product) === null || _item$attributes$prod4 === void 0 ? void 0 : (_item$attributes$prod5 = _item$attributes$prod4.old_prices) === null || _item$attributes$prod5 === void 0 ? void 0 : _item$attributes$prod5.length) > 0 ? true : false,
            discount: ((_item$attributes$prod6 = item.attributes.product) === null || _item$attributes$prod6 === void 0 ? void 0 : (_item$attributes$prod7 = _item$attributes$prod6.old_prices) === null || _item$attributes$prod7 === void 0 ? void 0 : _item$attributes$prod7.length) > 0 ? productDiscount : undefined,
            properties: item.attributes.product_properties.map(productProp => {
                return {
                    name: productProp.property_name,
                    value: productProp.value
                };
            }),
            image_url: item.attributes.image_url,
            slug: item.attributes.product.slug,
            in_stock: item.attributes.in_stock,
            pn: item.attributes.product_properties.map(item => {
                return {
                    static_id: item.static_id,
                    name: item.property_presentation,
                    value: item.value
                };
            }),
            state: item.attributes.product.total_on_hand < item.attributes.prices && item.attributes.prices[0].minimum_orderable_quantity ? 'soldout' : !item.attributes.in_stock ? item.attributes.product.total_on_hand > item.attributes.prices && item.attributes.prices[0].minimum_orderable_quantity ? 'expired' : 'soldout' : 'in stock'
        };
        itemsSelected.map(i => {
            if (item.id === i) {
                var _item$attributes3, _item$attributes3$pro, _item$attributes4, _item$attributes4$pro, _item$attributes5, _item$attributes5$pri, _item$attributes6, _item$attributes6$sup, _item$attributes7, _item$attributes7$pro, _item$attributes8, _item$attributes9, _item$attributes9$pro, _item$attributes10, _item$attributes10$pr;
                productsInfo.push(productInfo);
                gtmProducts.push({
                    item_name: item === null || item === void 0 ? void 0 : (_item$attributes3 = item.attributes) === null || _item$attributes3 === void 0 ? void 0 : (_item$attributes3$pro = _item$attributes3.product) === null || _item$attributes3$pro === void 0 ? void 0 : _item$attributes3$pro.name,
                    item_id: item === null || item === void 0 ? void 0 : (_item$attributes4 = item.attributes) === null || _item$attributes4 === void 0 ? void 0 : (_item$attributes4$pro = _item$attributes4.product) === null || _item$attributes4$pro === void 0 ? void 0 : _item$attributes4$pro.id,
                    price: (item === null || item === void 0 ? void 0 : (_item$attributes5 = item.attributes) === null || _item$attributes5 === void 0 ? void 0 : (_item$attributes5$pri = _item$attributes5.prices) === null || _item$attributes5$pri === void 0 ? void 0 : _item$attributes5$pri.length) > 0 && parseFloat(item.attributes.prices[0].amount),
                    item_brand: (_item$attributes6 = item.attributes) === null || _item$attributes6 === void 0 ? void 0 : (_item$attributes6$sup = _item$attributes6.supplier) === null || _item$attributes6$sup === void 0 ? void 0 : _item$attributes6$sup.name,
                    ...categoryNames(item === null || item === void 0 ? void 0 : (_item$attributes7 = item.attributes) === null || _item$attributes7 === void 0 ? void 0 : (_item$attributes7$pro = _item$attributes7.product) === null || _item$attributes7$pro === void 0 ? void 0 : _item$attributes7$pro.categories),
                    item_variant: itemVariant,
                    quantity: item === null || item === void 0 ? void 0 : (_item$attributes8 = item.attributes) === null || _item$attributes8 === void 0 ? void 0 : _item$attributes8.quantity,
                    tag_list: item === null || item === void 0 ? void 0 : (_item$attributes9 = item.attributes) === null || _item$attributes9 === void 0 ? void 0 : (_item$attributes9$pro = _item$attributes9.product) === null || _item$attributes9$pro === void 0 ? void 0 : _item$attributes9$pro.tag_list,
                    origin_supplier: item === null || item === void 0 ? void 0 : (_item$attributes10 = item.attributes) === null || _item$attributes10 === void 0 ? void 0 : (_item$attributes10$pr = _item$attributes10.product) === null || _item$attributes10$pr === void 0 ? void 0 : _item$attributes10$pr.origin_supplier
                });
            }
        });
    });
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        event: GTM_EVENT.REMOVE_FROM_CART,
        ecommerce: {
            items: gtmProducts
        }
    });
};
export const addToCartHeleper = (data, quantity) => {
    addToCartGtmHelper(data, quantity, data === null || data === void 0 ? void 0 : data.itemListName, data === null || data === void 0 ? void 0 : data.itemListId);
};
export const selectPromotionGtmHelper = (metadata_list, creativeName, creativeSlot) => {
    if (metadata_list && creativeName && creativeSlot) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: GTM_EVENT.SELECT_PROMOTION,
            ecommerce: {
                items: [{
                        promotion_id: metadata_list === null || metadata_list === void 0 ? void 0 : metadata_list.module_template,
                        promotion_name: (metadata_list === null || metadata_list === void 0 ? void 0 : metadata_list.title_text) + "_" + (metadata_list === null || metadata_list === void 0 ? void 0 : metadata_list.title_separator),
                        creative_name: creativeName,
                        creative_slot: creativeSlot
                    }]
            }
        });
    }
    else
        return;
};
export const clickOnSliderImageHelper = _ref => {
    var _module$promotional_l, _module$promotional_l2;
    let { image, module, position, index } = _ref;
    viewPromotionGtmHelper(module);
    selectPromotionGtmHelper(module === null || module === void 0 ? void 0 : module.metadata_list, module === null || module === void 0 ? void 0 : (_module$promotional_l = module.promotional_links[0]) === null || _module$promotional_l === void 0 ? void 0 : _module$promotional_l.query.title, module === null || module === void 0 ? void 0 : (_module$promotional_l2 = module.promotional_links[0]) === null || _module$promotional_l2 === void 0 ? void 0 : _module$promotional_l2.image.url);
    let clicked_link_info;
    module.promotional_links.map(element => {
        if (element.image.url === image) {
            clicked_link_info = {
                id: element.id,
                is_link_url: element.query.link_url !== '' ? true : false,
                link_url: element.query.link_url !== '' ? element.query.link_url : undefined,
                image_url: element.image.url,
                created_at: element.created_at,
                updated_at: element.updated_at,
                position: position === 999 || position === '' ? '' : position,
                index: index + 1
            };
        }
    });
};
export const clickOnSmallIconHelper = (_ref2, _ref3) => {
    let { metadata_list, promotional_links } = _ref2;
    let { image, query } = _ref3;
    viewPromotionGtmHelper({
        metadata_list,
        promotional_links
    });
    selectPromotionGtmHelper(metadata_list, query === null || query === void 0 ? void 0 : query.title, image === null || image === void 0 ? void 0 : image.url);
};
export const promoModuleInteractionHelper = (module, creativeName, creativeSlot) => {
    viewPromotionGtmHelper(module);
    selectPromotionGtmHelper(module === null || module === void 0 ? void 0 : module.metadata_list, creativeName, creativeSlot);
};
export const useReactPath = () => {
    const [path, setPath] = useState(window.location.href);
    useEffect(() => {
        globalHistory.listen(_ref4 => {
            let { location } = _ref4;
            const winPath = location.href;
            setPath(winPath);
        });
    }, []);
    return path;
};
export const viewCartGtmHelper = products => {
    const cartGTMProducts = products.map((item, index) => {
        var _item$attributes11, _item$attributes11$pr, _item$attributes12, _item$attributes13, _item$attributes14, _item$attributes15, _item$attributes15$su, _item$attributes16, _item$attributes16$pr, _item$attributes17, _item$attributes17$pr, _item$attributes18, _item$attributes18$pr, _item$attributes19;
        let itemVariant = undefined;
        item === null || item === void 0 ? void 0 : (_item$attributes11 = item.attributes) === null || _item$attributes11 === void 0 ? void 0 : (_item$attributes11$pr = _item$attributes11.product_properties) === null || _item$attributes11$pr === void 0 ? void 0 : _item$attributes11$pr.forEach(spec => {
            if ((spec === null || spec === void 0 ? void 0 : spec.static_id) == 'S20') {
                itemVariant = spec === null || spec === void 0 ? void 0 : spec.value;
            }
        });
        return {
            item_name: item === null || item === void 0 ? void 0 : (_item$attributes12 = item.attributes) === null || _item$attributes12 === void 0 ? void 0 : _item$attributes12.name,
            item_id: (item === null || item === void 0 ? void 0 : (_item$attributes13 = item.attributes) === null || _item$attributes13 === void 0 ? void 0 : _item$attributes13.variant_id) ? item.attributes.variant_id.toString() : undefined,
            price: (item === null || item === void 0 ? void 0 : (_item$attributes14 = item.attributes) === null || _item$attributes14 === void 0 ? void 0 : _item$attributes14.price) ? parseFloat(item.attributes.price) : undefined,
            item_brand: (_item$attributes15 = item.attributes) === null || _item$attributes15 === void 0 ? void 0 : (_item$attributes15$su = _item$attributes15.supplier) === null || _item$attributes15$su === void 0 ? void 0 : _item$attributes15$su.name,
            ...categoryNames(item === null || item === void 0 ? void 0 : (_item$attributes16 = item.attributes) === null || _item$attributes16 === void 0 ? void 0 : (_item$attributes16$pr = _item$attributes16.product) === null || _item$attributes16$pr === void 0 ? void 0 : _item$attributes16$pr.categories),
            item_variant: itemVariant,
            tag_list: item === null || item === void 0 ? void 0 : (_item$attributes17 = item.attributes) === null || _item$attributes17 === void 0 ? void 0 : (_item$attributes17$pr = _item$attributes17.product) === null || _item$attributes17$pr === void 0 ? void 0 : _item$attributes17$pr.tag_list,
            origin_supplier: item === null || item === void 0 ? void 0 : (_item$attributes18 = item.attributes) === null || _item$attributes18 === void 0 ? void 0 : (_item$attributes18$pr = _item$attributes18.product) === null || _item$attributes18$pr === void 0 ? void 0 : _item$attributes18$pr.origin_supplier,
            index: index,
            quantity: item === null || item === void 0 ? void 0 : (_item$attributes19 = item.attributes) === null || _item$attributes19 === void 0 ? void 0 : _item$attributes19.quantity
        };
    });
    window.dataLayer.push({
        event: GTM_EVENT.VIEW_CART,
        ecommerce: {
            items: cartGTMProducts
        }
    });
};
